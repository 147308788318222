import InfoIcon from "@mui/icons-material/Info";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CollectionsIcon from "@mui/icons-material/Collections";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HomeIcon from "@mui/icons-material/Home";
export class UIConstants {
  static carouselData = [
    { image: require("../../Assets/Images/Carousel/1.jpg"), name: "Image 1" },
    { image: require("../../Assets/Images/Carousel/2.jpg"), name: "Image 2" },
    { image: require("../../Assets/Images/Carousel/3.jpg"), name: "Image 2" },
  ];

  static homeData = [
    {
      image: require("../../Assets/Images/Services/sh1.jpeg"),
      name: "Smart Homes",
      path: "smart-homes",
    },
    {
      image: require("../../Assets/Images/Services/sh4.jpeg"),
      name: "Interior Design",
      path: "interior-design",
    },
    {
      image: require("../../Assets/Images/Services/Services/villa.png"),
      name: "Construction of Villa",
      path: "villa-construction",
    },
    {
      image: require("../../Assets/Images/Services/sh5.jpeg"),
      name: "MEP Services",
      path: "MEP-services",
    },
    {
      image: require("../../Assets/Images/Services/sh9.jpeg"),
      name: "Indoor & Outdoor Lights",
      path: "indore-outdoor-lights",
    },
    {
      image: require("../../Assets/Images/Services/sh2.jpeg"),
      name: "Aviation Ligtings",
      path: "aviation-lightings",
    },
    {
      image: require("../../Assets/Images/Services/sh3.jpeg"),
      name: "Traffic Lights & Controllers",
      path: "traffic-lights",
    },
    {
      image: require("../../Assets/Images/Services/sh6.jpeg"),
      name: "GPS Vehicle Tracker",
      path: "gps-vehicle-tracker",
    },
    {
      image: require("../../Assets/Images/Services/sh8.jpeg"),
      name: "Swimming Pool Construction",
      path: "swimming-pool-construction",
    },
    {
      image: require("../../Assets/Images/Services/sh12.jpeg"),
      name: "FRP and WPC Products",
      path: "FRP-WPC",
    },
    {
      image: require("../../Assets/Images/Services/sh10.jpeg"),
      name: "Outdoor & Indoor Gym Equipments",
      path: "gym-equipments",
    },
    {
      image: require("../../Assets/Images/Services/sh11.jpeg"),
      name: "Kids Playground Equipments",
      path: "kids-playground-equipments",
    },
    {
      image: require("../../Assets/Images/Services/sh13.jpeg"),
      name: "Landscaping & Gardening",
      path: "landscaping-gardening",
    },
    {
      image: require("../../Assets/Images/Services/Services/CCTV.png"),
      name: "Electronic Gadgets",
      path: "electronic-gadgets",
    },
    {
      image: require("../../Assets/Images/Services/Services/parking.jpeg"),
      name: "Electric Parking Gates",
      path: "electric-parking-gates",
    },
    // {
    //   image: require('../../Assets/Images/Services/Services/RO.jpeg'),
    //   name: 'RO Water Treatment Plant',
    //   path: 'ro-water-treatment-plant',
    // },
    {
      image: require("../../Assets/Images/Services/Services/solar.jpeg"),
      name: "Solar Services",
      path: "solar-services",
    },
    {
      image: require("../../Assets/Images/Services/Services/flexiUG.jpeg"),
      name: "Flexi Underground Solutions",
      path: "flexi-underground-solutions",
    },
    {
      image: require("../../Assets/Images/Services/Services/Hologram.jpeg"),
      name: "3D Hologram Display",
      path: "3d-hologram-display",
    },
    {
      image: require("../../Assets/Images/Services/Services/kids.jpeg"),
      name: "Specialized Kid's Interior Decoration",
      path: "specialized-kids-interior-decoration",
    },
    {
      image: require("../../Assets/Images/Services/Services/web.jpeg"),
      name: "Website Development",
      path: "website-development",
    },
  ];

  static pages = [
    { name: "Home", icon: <HomeIcon />, path: "/" },
    { name: "About", icon: <InfoIcon />, path: "/about" },
    { name: "Services", icon: <EngineeringIcon />, path: "/services" },
    // { name: 'Projects', icon: <AccountTreeIcon />, path: '/projects' },
    { name: "Gallery", icon: <CollectionsIcon />, path: "/gallery" },
    { name: "Contact", icon: <ContactPageIcon />, path: "/contact" },
  ];

  static settings = [
    { name: "Shop", value: "https://shop.eagleeye-worldwide.com/products" },
  ];

  // static GallerySetting = {
  //   photos: [
  //     // {
  //     //   source:
  //     //     'https://images.unsplash.com/photo-1517088455889-bfa75135412c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=e5548929376f93d8b1b7a21097df03bd&auto=format&fit=crop&w=749&q=80',
  //     // },
  //     // {
  //     //   source:
  //     //     'https://images.unsplash.com/photo-1526656892012-7b336603ed46?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=31c8e58b58c25dfcc18452ed29b52951&auto=format&fit=crop&w=334&q=80',
  //     // },
  //   ],
  //   showNumOfRemainingPhotos: true,
  // };
}
export default UIConstants;
